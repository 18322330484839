import React, {useContext} from 'react';
import propTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {Box, FormHelperText, Typography} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {serialize} from "../helpers/serialize";
import BootstrapTextInput from "../Atoms/FormElements/BootstrapTextInput";
import {documentStore} from "documentStore";

const FormElement = ({
  id,
  label = 'No Label',
  type = 'text',
  component = '',
  textValue= 'Insert Message',
  url = '',
  width = '198px',
  hidden = false,
  defaultValue = '',
  selectValues = [],
  oneSelectable = false,
  variant = 'outlined',
  values,
  errors,
  touched,
  handleBlur = () => {},
  handleChange,
  setFieldValue
}) => {
  const {docState} = useContext(documentStore);

  const elementStyle = {
    width: '100%'
  }

  const selectOption = (index) => {
    const newValue = values[id].map((selVal, selValIndex) => {
      if (selValIndex === index) {
        return {...selVal, selected: !selVal.selected}
      } else {
        if (oneSelectable) {
          return {...selVal, selected: false};
        } else {
          return selVal
        }
      }
    });
    setFieldValue(id , newValue);
  }

  return (
    <React.Fragment>
      {!hidden && <React.Fragment>
      {type === 'textField' && (
        <FormControl variant="standard" sx={{width: '99%'}}>
          <Box component={'label'} sx={{
            color: 'text.primary',
            fontSize:16,
            lineHeight:'20px',
            position: 'relative',
            overflow: 'visible',
            whiteSpace: 'break-spaces',
            marginBottom: '5px'
          }}>{label}</Box>
          <BootstrapTextInput
            id={id}
            label={label}
            value={values[id]}
            onChange={handleChange}
            onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
            onBlur={handleBlur}
            error={touched[id] && Boolean(errors[id])}
            variant={variant}
            style={elementStyle}
          />
          <FormHelperText id="component-error-text" error={touched[id] && Boolean(errors[id])}>{touched[id] ? errors[id] : ''}</FormHelperText>
        </FormControl>
      )}

      {(type === 'text' || type === 'typography') &&
        <React.Fragment>
         {(typeof textValue === 'string' || textValue instanceof String) && <div>{textValue}</div>}
        </React.Fragment>
      }

      {(type === 'content' && !component) &&
        <React.Fragment>
          {(typeof textValue === 'string' || textValue instanceof String) && <div>{textValue}</div>}
          {(typeof textValue !== 'string') && <div dangerouslySetInnerHTML={{__html: serialize({children: textValue})}} />}
        </React.Fragment>
      }

      {type === 'image' && (
        <Box
          component="img"
          src={url}
          sx={{
            maxWidth: '100%',
            maxHeight: '100%',
            display:'block',
            margin:'53px auto',
            width: (typeof width === 'string' || width instanceof String) ? width :  {...width}
          }}
          alt={'Butterfly'} />
      )}

      {type === 'boolean' && (
        <FormControlLabel
          variant="outlined"
          control={
            <Switch
              id={id}
              checked={values[id]}
              onChange={handleChange}
              name={id}
              color="primary"
            />
          }
          label={label}
        />
      )}

      {type === 'datetime' && (
        <LocalizationProvider  dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={values[id]}
          format="dd/MM/yyyy"
          inputVariant="outlined"
          style={elementStyle}
          onChange={(date) => {setFieldValue(id, date)}}
          renderInput={(params) => <TextField {...params} style={elementStyle} />}
        />
        </LocalizationProvider>
      )}

      {(type === 'multiselect' || type === 'multiSelect') && (
        <div style={elementStyle}>
          {textValue && textValue !==  'Insert Message' && <Typography component={'div'} sx={{
              color: docState.present.kiteTheme.text.statement || docState.present.kiteTheme.primary.main,
              fontSize: '16px',
              fontWeight: 'normal',
              margin: '0 0 12px 0'
          }}>{textValue}</Typography>}
          <Box id="position-label" sx={{marginBottom:'16px', fontSize:15,  color: 'text.primary'}}>{label}</Box>
          <Box sx={{display: 'grid',
            rowGap: '15px',
            columnGap: '12px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))'}}>
          {selectValues.map((value, index) => (
            <React.Fragment key={value.title} >
            {value.title !== '' && <Box
              tabIndex={0}
              onClick={(e) => {
                selectOption(index);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  selectOption(index);
                }
              }}
              value={value.title}
              key={value.title}
              className={`${values[id] && values[id][index] && values[id][index].selected ? 'selected': ''}`}
              sx={{
                padding:'14px 6px',
                textAlign:'center',
                backgroundColor: '#fff',
                border:'1px solid #ccc',
                borderColor:  docState.present.kiteTheme.text.statement || docState.present.kiteTheme.primary.main,
                borderRadius:'2px',
                cursor: 'pointer',
                fontWeight: 'bold',
                transition: '.3s',
                fontSize:'16px',
                color: 'text.primary',
                '@media (hover: hover) and (pointer: fine)': {
                  '&:hover': {
                    boxShadow: `0 0 3px 0 ${docState.present.kiteTheme.text.statement || docState.present.kiteTheme.primary.main}`,
                  },
                },
                '&.selected': {
                  backgroundColor: docState.present.kiteTheme.multiColour || docState.present.kiteTheme.primary.main,
                  color: docState.present.kiteTheme.text.statement ? '#fff' : 'primary.contrastText'
                }
            }}>
              {value.title}
            </Box>}</React.Fragment>
          ))}
          </Box>
          {values[id] && values[id].length && !!values[id].filter(option => ((option.title.indexOf('Other') !== -1) && option.selected)).length &&
          <FormControl variant="standard" sx={{width: '100%', marginTop:'16px'}}>
            <InputLabel shrink htmlFor="bootstrap-input" sx={{color: 'text.primary', fontSize:20}}>
              If you selected other, please specify...
            </InputLabel>
            <BootstrapTextInput
              id={id}
              label={'If you selected other, please specify...'}
              onChange={(e) => {
                const newValue = values[id].map(selVal => {
                  if (selVal.title.indexOf('Other') !== -1) {
                    return {...selVal, selected: true, otherValue: e.target.value}
                  } else return selVal;
                });
                setFieldValue(id , newValue);
              }}
              onBlur={handleBlur}
              helperText={touched[id] ? errors[id] : ''}
              error={touched[id] && Boolean(errors[id])}
              variant={variant}
              style={elementStyle}
            />
          </FormControl>}
          <FormHelperText id="component-error-text" error={touched[id] && Boolean(errors[id])}>{touched[id] ? errors[id] : ''}</FormHelperText>
        </div>
      )}

      {type === 'select' && (
        <FormControl variant="outlined" style={elementStyle}>
          <InputLabel id="position-label">{label}</InputLabel>
          <Select
            labelId={`${id}-label`}
            label={label}
            id={id}
            variant={variant}
            value={values[id] || defaultValue}
            onChange={a => {
              a.target.id = id;
              handleChange(a);
            }}
            onBlur={a => {
              a.target.id = id;
              handleBlur(a);
            }}
          >
            {selectValues.map(value => (
              <MenuItem value={value.value || value.title} key={value.title}>
                {value.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      </React.Fragment>}
    </React.Fragment>
  );
};

FormElement.propTypes = {
  id: propTypes.string,
  type: propTypes.string,
  label: propTypes.string,
  value: propTypes.string,
  selectValues: propTypes.array,
  values: propTypes.object,
  errors: propTypes.object,
  touched: propTypes.object,
  handleBlur: propTypes.func,
  handleChange: propTypes.func
};

export default FormElement;
